import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Post = ({ pageContext }) => {
  return (
    <Layout>
      <Seo title={pageContext?.data?.title} />

      <section className="page-single">
        <div className="container-fluid">
          <h1>
            <div
              dangerouslySetInnerHTML={{ __html: pageContext?.data?.title }}
            />
          </h1>
          <div
            className="page-single-thumb"
            style={{
              backgroundImage: `url(${pageContext?.data?.data?.featuredImage?.node?.localFile?.publicURL})`,
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: pageContext?.data?.data?.content,
            }}
            className="page-single--inner"
          />
        </div>
        <span className="page-single-nav--header">Les andre artikler</span>
        <div className="page-single-nav">
          <div className="row align-items-center">
            <div className="col-sm-6 col_1">
              {pageContext?.data?.prev && (
                <>
                  <h3>{pageContext?.data?.prev?.title}</h3>
                  <div>
                    <Link
                      to={pageContext?.data?.prev?.uri}
                      className="btn_custom arrow arrow-left"
                    >
                      Forrige innlegg
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="col-sm-6 col_2">
              {pageContext?.data?.next && (
                <>
                  <h3>{pageContext?.data?.next?.title}</h3>
                  <div>
                    <Link
                      to={pageContext?.data?.next?.uri}
                      className="btn_custom arrow arrow-right"
                    >
                      Neste innlegg
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Post
